import React from "react";
import MainApi from "./MainApi";
import {findErrorResponse} from "./utils_api";
import {LS_CLIENT_TOKEN, LS_FIRM, LS_LANG, LS_LINK_T0_3D_CARD, LS_USER_TOKEN} from "../constants";
import {initialProcessingEdgeEditProjectForPart} from "./External/helpers_processing_edge_api";
import {getUiIdForPart} from "../helpers/helpers_part";

class ApiService extends MainApi {

    constructor() {
        super();
    }


    //TODO: ссылка под замену (АПИ)

    // _mainLink = 'https://test2-go.ifurn.pro';
    // _glLink = 'https://test2-gl.ifurn.pro';
    // _crmLink = 'https://test2-crm.ifurn.pro';

    // _baseUrl = 'https://test2-api.ifurn.pro/api';
    // _mainLink = 'https://test2-go.ifurn.pro';
    // _glLink = 'https://test2-gl.ifurn.pro';
    // _crmLink = 'https://test2-crm.ifurn.pro';

    // _baseUrl = 'https://test-api.ifurn.pro/api';
    // _mainLink = 'https://test-go.ifurn.pro';
    // _glLink = 'https://test-gl.ifurn.pro';
    // _crmLink = 'https://test-crm.ifurn.pro';


    //_baseUrl = 'https://api.ifurn.pro/api';
    //_mainLink = 'https://go.ifurn.pro';
    //_glLink = 'https://gl.ifurn.pro';
    //_crmLink = 'https://crm.ifurn.pro';

    // _baseUrl = 'http://api-local.ifurn.pro/api';
    // _mainLink = 'http://go-local.ifurn.pro';
    // _glLink = 'http://gl-local.ifurn.pro';
    // _crmLink = 'http://crm-local.ifurn.pro';

    _withCredentials = "include";
    getProject = async (version, code, client, firm = localStorage.getItem("ifurn_service_main_user_firm"), clean = true, get_version = null, processing_edge_point) => {
        if (!code) return false;
        const need_clean = clean ? "&clean=1" : "";
        let url = `/projects/${version}/?client=${client}&order=${code}&firm=${firm}${need_clean}`;
        if (get_version) {
            url += `&get_version=${get_version}`
        }
        const res = await this._send(
            url,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res?.link_to_cad) {
            localStorage.setItem(LS_LINK_T0_3D_CARD, res?.link_to_cad)
        }
        if (res && res.order && res.order.id) {
            return this._transformProject(res.order, processing_edge_point);
        } else {
            return false;
        }
    };
    _transformProject = (data, processing_edge_point) => {

        try {
            let bands_material = data?.material?.filter(item => !!Number(item.band_only_connected) && Array.isArray(item?.connected_band))?.map(item => item.connected_band)?.flat();
            let un_bands = bands_material?.reduce((acc, item) => {
                let is_has_band = data?.band.find(band => Number(band.goods_id) === Number(item.goods_id));
                if (!is_has_band) {
                    acc.push(item)
                }
                return acc
            }, [])?.flat()?.filter(item => !!item);
            let band = data?.band && Array.isArray(data.band) ? [...data.band, ...un_bands] : un_bands;
            return {
                goods_service_added_part_firm: data?.goods_service_added_part_firm || [],
                goods_service_added_order_firm: data?.goods_service_added_order_firm || [],
                gr: data?.gr || [],
                packing: data?.packing ?? null,
                api_error_calc: data?.api_error_calc ?? '',
                firm_contact: data?.firm_contact ?? null,
                firm_name: data?.firm_name,
                user_name: data?.user_name,
                confirm_data: data?.confirm_data || {},
                message_calculate: data?.message_calculate ?? "",
                save_versions: data?.save_versions ?? [],
                versions: data?.versions ?? [],
                client_name: data.hasOwnProperty("client_name") ? data.client_name : "",
                client_phone_code: data?.client_phone_code?.replace(" ", "") ?? false,
                client_email: data?.client_email ?? "",
                client_phone: data.hasOwnProperty("client_phone") ? data.client_phone?.replace(/\D/g, "") : "",
                calculate_vals: data.hasOwnProperty("calculate_vals") ? data.calculate_vals : [],
                id: data.id,
                title: data.title ? data.title : "Новый (Untitled) проект",
                lang: data.lang ? data.lang : "ru",
                date: data.date ? data.date : "2021-08-19 15:00:00",
                client: data.client ? data.client : null,
                firm: data.firm ? data.firm : localStorage.getItem("ifurn_service_main_user_firm"),
                currency: data.currency ? data.currency : 2,
                code: data.code ? data.code : null,
                goods: data.goods ? data.goods : [],
                boxes: data.boxes ? data.boxes : [],
                cutting_card: data.cutting_card ?? null,
                cutting_card_in: data?.cutting_card_in,
                part: data.part ? data.part.map(e => {
                    if (!e.srez) {
                        e.srez = {
                            t: {
                                otstup: "",
                                ugol: "",
                                db_id: ""
                            },
                            b: {
                                otstup: "",
                                ugol: "",
                                db_id: ""
                            },
                            l: {
                                otstup: "",
                                ugol: "",
                                db_id: ""
                            },
                            r: {
                                otstup: "",
                                ugol: "",
                                db_id: ""
                            }
                        };
                    }
                    ["t", "b", "l", "t"].forEach(b => {
                        if (Number(e.edge[b].db_id) === -1) {
                            const band = {...e.edge[b]};
                            e.srez[b].ugol = band.radius;
                            e.edge[b].db_id = null;
                            e.edge[b].cut = null;
                            e.edge[b].radius = null;
                        }
                    });

                    if (e?.operations?.bore?.length) {
                        let isOnXncZenk = data?.production_constants?.['production.xnc_bore_zenk_on'] || 0
                        e?.operations?.bore?.forEach(item => {
                            if (!item.hasOwnProperty("start_point")) {
                                item["start_point"] = "ld";
                            }
                            if (['f', 'bb'].includes(item.side) && item.hasOwnProperty('zenkovanie') && !Number(isOnXncZenk)) {
                                item['zenkovanie'] = 'no'
                            }
                        });
                    }

                    if (e.operations) {
                        let bore = e.operations?.bore?.length ? e.operations.bore?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item)))
                            : [];
                        e.operations = {
                            ...e.operations,
                            bore: bore,
                            // bore: e.operations?.bore?.length ? e.operations.bore?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
                            hem: e.operations?.hem?.length ? e.operations?.hem?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
                            cut_to: e.operations?.cut_to?.length ? e.operations?.cut_to?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
                            frez: e.operations?.frez?.length ? e.operations?.frez?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
                            trough: e.operations?.trough?.length ? e.operations?.trough?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
                            paz: e.operations?.paz?.length ? e.operations?.paz?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item)))
                                : [],

                        }
                    }
                    let payload_edge_edit = initialProcessingEdgeEditProjectForPart(e, data.production_constants, processing_edge_point)
                    let uuid = {}
                    if (!e?.hasOwnProperty('uuid') || !(typeof e?.uuid === "string") ) {
                        uuid = getUiIdForPart()
                    }

                    return {
                        ...e, ...payload_edge_edit,
                        ...uuid
                    };
                }) : [],
                //TODO:: map изм на filter чтобы в массив не попадали null undefined и тд
                material: data.material ? data.material.filter(e => {
                    if (e) {
                        if (!e.hasOwnProperty("additional") || Array.isArray(e?.additional)) {
                            e.additional = {
                                // triml: '',
                                // trimw: '',
                                // direction: '',
                                trimL: "",
                                trimW: "",
                                rez_napr: "",
                                active: 1,
                                turn: "",
                                hard_cut: ""
                            };
                        } else {
                            e.additional = {
                                ...e.additional,
                                active: e.additional?.hasOwnProperty("active") ? e.additional.active : 1
                            };
                        }

                        if (!e.z) {
                            e.z = 16;
                            e.haveError = true;
                        }

                        e.band_only_connected = e?.hasOwnProperty("band_only_connected") ? e?.band_only_connected : 0;
                        return e;
                    }

                }) : [],
                band: band,
                bands_type: data.bands_type ? data.bands_type.map(e => {
                    if (!e.glue) {
                        e.glue = "transparent";
                    }

                    return e;
                }) : [],
                product: data.product ? data.product : [],
                extra: data.extra ? data.extra : [],
                plane_operations: data.plane_operations ? data.plane_operations.map(e => {
                    return {
                        ...e,
                        id: e.id,
                        add_to_detail: e?.add_to_detail ?? null,
                        name: e.name ? e.name : "Название не указано",
                        code_1c: e.code_1c ? e.code_1c : 0,
                        layer_z: e.layer_z ? e.layer_z : 0,
                        expense: e.expense ? e.expense : 0,
                        unit: e.unit ? e.unit : 0,
                        type: e.type ? e.type : "",
                        type_name: e.type_name ? e.type_name : "Название не указано",
                        image: e.image ? e.image : "",
                    };
                }) : [],
                production_constants: data.production_constants ? data.production_constants : [],
                startMessage: data.startMessage ? data.startMessage : true,
                calculate: data.calculate ? data.calculate : [],
                calculate_types: data.calculate_types ? data.calculate_types : [],
                calc: data.calc ? data.calc : null,
                calculate_discounts: {
                    material: data.calculate_discounts && data.calculate_discounts.material ? data.calculate_discounts.material : [],
                    service: data.calculate_discounts && data.calculate_discounts.service ? data.calculate_discounts.service : [],
                    goods: data.calculate_discounts && data.calculate_discounts.goods ? data.calculate_discounts.goods : []
                },
                partSizesType: data.partSizesType ? data.partSizesType : "saw",
                universal_calculate: 1,
                urgent: 0,
                status: data.status
            };
        } catch (e) {
            console.log(e)
        }
    };
    saveProject = async (version, order) => {
        if (!version || !order) return false;
        let time1 = performance.now();
        const res = await this._send(
            `/projects/${version}/`,
            "PUT",
            {
                order: order
            },
            {},
            "json"
        );
        let time2 = performance.now();
        let timeFetch = (time2 - time1).toFixed(4) * 0.001;
        if (timeFetch > 3 && res.status !== "Data updated.") {
            return {
                is_save: false,
                res: res
            };
        }
        if (res && res.status && res.status === "Data updated.") {
            return {
                is_save: true,
                res: res
            };
        } else {
            return {
                is_save: false,
                res: res
            };
        }
    };
    saveProjectVersion = async (version, order) => {
        if (!version || !order) return false;

        const res = await this._send(
            `/projects/${version}/`,
            "POST",
            {
                order: order
            },
            {},
            "json"
        );
        if (res && res.status && res.status === "complete" && res.version) {
            return res;
        } else {
            return false;
        }
    };
    saveProjectFile = async (order) => {
        if (!order) return false;

        const res = await this._send(
            `/import/`,
            "POST",
            {
                action: "save_project",
                order: order
            },
            {},
            "json"
        );
        if (res && res.link) {
            return res.link;
        } else {
            return false;
        }
    };

    createOrder = async (client, firm, title_project) => {

        if (!firm) firm = localStorage.getItem("ifurn_service_main_user_firm");

        if (!client || !firm) return false;

        const res = await this._send(
            `/orders/`,
            "POST",
            {
                client: client,
                firm: firm, title: title_project
            },
            {},
            "json"
        );
        if (res && res.data && res.status === "complete" && res.data.code) {
            return res.data.code;
        } else {
            return false;
        }
    };

    async sendProjectFiles(order, files) {

        const formData = new FormData();

        formData.append("action", "import_order");
        formData.append("order", order);
        formData.append("firm", localStorage.getItem("ifurn_service_main_user_firm"));

        for (let i = 0; i < files.length; i++) {
            formData.append("import_porject_" + i, files[i]);
        }

        const res = await fetch(`${this._baseUrl}/import`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
            body: formData
        });

        let response = await res.json();

        if (response && response.hasOwnProperty('error_api')) {

            let api_error = response.error_api?.api_error?.reduce((acc, item) => {
                return `${acc} ${item?.error}`
            }, '')
            return {error: api_error}
        }
        if (response && response.order) {
            return response.order;
        } else {
            return false;
        }

    }

    getTools = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/tools/equipment/?firm=${firm}`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return this._transformTools(res.data);
        } else {
            return false;
        }
    };

    _transformTools = (data) => {
        return data.map(e => {
            return e;
        });
    };

    getBoxes = async () => {

        const res = await this._send(
            `/boxes`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    };

    constructBox = async (box, deletePartsDetail = [], updateGeneral = []) => {

        if (!box) return false;

        const res = await this._send(
            `/boxes`,
            "POST",
            {
                ...box,
                deleteDetail: deletePartsDetail,
                general: updateGeneral

            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getBoxParams = async (id) => {
        if (!id) return false;
        const res = await this._send(
            `/boxes/` + id,
            "GET",
            {},
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getHemTypes = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/doubles`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return this._transformHemTypes(res.data);
        } else {
            return false;
        }
    };

    _transformHemTypes = (data) => {
        return data.map(e => {
            return e;
        });
    };

    getPartSizes = async (part, bands_type, materials, bands, client, firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!part) return false;

        const res = await this._send(
            `/sizes`,
            "POST",
            {
                part: part,
                materials: materials,
                bands: bands,
                bands_type: bands_type,
                firm: firm,
                client: client
            },
            {},
            "json"
        );
        if (res && res.part) {
            return res.part;
        } else {
            return false;
        }
    };

    getAllPartSizes = async (parts, bands_type, materials, bands, client, allMaterials = null, allBands = null, firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!parts) return false;

        const res = await this._send(
            `/sizes`,
            "POST",
            {
                type: "all",
                parts: parts,
                materials: materials,
                bands: bands,
                bands_type: bands_type,
                firm: firm,
                allMaterials: allMaterials,
                allBands: allBands,
                client: client
            },
            {},
            "json"
        );
        if (res && res.parts) {
            return res.parts;
        } else {
            return false;
        }
    };


    getTemplatesTree = async (glassTree = false) => {
        let url = glassTree ? "glass_tree/" : "tree/";
        const res = await this._send(
            `/templates/${url}`,
            "GET",
            {},
            {credentials: "include"},
            "json"
        );
        if (res && res.data) {
            return this._transformTemplatesTree(res.data);
        } else {
            return false;
        }
    };

    _transformTemplatesTree = (data) => {
        return data.map(e => {
            return {
                id: e.id,
                name: e.name,
                childs: e.parents,
                templates: e.templates
            };
        });
    };

    getProducers = async () => {

        const res = await this._send(
            `/materials/producers/`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return this._transformProducers(res.data);
        } else {
            return false;
        }
    };

    _transformProducers = (data) => {
        return data.map(e => {
            return {
                id: e.producers_id,
                name: e.name
            };
        });
    };


    getTemplateForm = async (id, firm = localStorage.getItem(LS_FIRM)) => {

        if (!id) return false;

        const res = await this._send(
            `/templates/${id}/?firm=${firm}`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return res;
        } else {
            return false;
        }
    };

    getTemplateOperations = async (id, band, part, form, firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!id || !band || !part || !form) return false;

        const res = await this._send(
            `/templates/`,
            "POST",
            {
                formData: form,
                id: id,
                band: band,
                partData: part,
                firm: firm
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    checkProject = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/check-project/`,
            "POST",
            {
                order: order
            },
            {},
            "json"
        );
        if (res && res.project && res.project.error) {
            return {
                parts: res.project.error,
                xnc: res.xnc_error
            };
        } else {
            return false;
        }
    };

    getGiblabData = async (order, action, part_id = null) => {

        if (!order || !action) return false;

        const res = await this._send(
            `/giblab/`,
            "POST",
            {
                action: action,
                part_id: part_id,
                order: order
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    calculateOrder = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/calculate/`,
            "POST",
            {
                order: order,
                materials: order.calculate_types,
                discounts: order.calculate_discounts,
                calc: order.calc
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getAuth = async (email, password, from = '') => {
        if (!email || !password) return false;
        let data = await fetch(`${this._baseUrl}/users/auth/?email=${email}&pass=${password}&from=${from}`, {
            method: "GET",
            mode: 'cors',
            cache: 'no-cache',
            credentials: this._withCredentials,
            headers: {
                'Content-Type': 'application/json',
                'User-Token': localStorage.getItem(LS_USER_TOKEN),
                'Client-Token': localStorage.getItem(LS_CLIENT_TOKEN),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem(LS_LANG) || 'ua',

            },
            redirect: 'follow',
            referrerPolicy: 'unsafe-url',
        })

        if (data.status === 404) {
            return {
                status: 404
            };
        }
        let res = await data.json();
        if (res && res.data && res.data.auth_data && res.data.firms) {
            return this._transformAuth(res.data);
        } else {
            return {
                status: 403
            };
        }
    };

    _transformAuth = (data) => {
        return {
            status: "success",
            client: data?.client ?? null,
            id: data.auth_data.client_id,
            token: data.auth_data.user_data.token,
            firm: data.auth_data?.firm ?? null,
            firms: data.firms.map(e => {
                return {
                    id: e.firm,
                    name: e.firm_name
                };
            })
        };
    };

    importProject = async (files, url = "import", data = null) => {

        if (!files) return false;

        const res = await this._sendFileData(
            `/${url}`,
            files,
            data
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    importProjectChanges = async (projects, new_goods, order, array_name, projects_name, arr) => {

        if (!order || !projects || !new_goods) return false;

        const res = await this._send(
            `/import/`,
            "POST",
            {
                projects: projects,
                projects_name: projects_name,
                new_goods: new_goods,
                current_project: order,
                name_part_put: {
                    main: array_name,
                    arr: arr
                }
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getRegions = async () => {
        return {
            ua: {id: "ua", label: "UA +38", code: "+38", mask: "(111) 111-11-11"},
            he: {id: "he", label: "HE +972", code: "+972", mask: "111-111-1111"},
            // ru: {id: 'ua', label: 'RU +7', code: '+7', mask: '(111) 111-11-11'},
            by: {id: "by", label: "BY +375", code: "+375", mask: "(11) 111-11-11"},
            bg: {id: "bg", label: "BG +359", code: "+359", mask: "(11) 111-11-11"},
            pl: {id: "pl", label: "PL +48", code: "+48", mask: "(11) 111-11-11"}
        };
    };

    getStores = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/confirm/store/` + firm,
            "GET",
            {},
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    confirmOrder = async (confirm_data, order, amount) => {

        if (!confirm_data || !order) return false;

        const res = await this._send(
            `/confirm/`,
            "POST",
            {
                data: {
                    ...confirm_data,
                    check_contact: "ok"
                },
                order_code: order.code,
                amount: amount,
                calculate: order.calculate,
                materials: order.material,
                extra: order.extra,
                currency: order.currency,
                goods_service_added_part_firm: order?.goods_service_added_part_firm || null,
                goods_service_added_order_firm: order?.goods_service_added_order_firm || null,
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    xmlTransform = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/project-transform/`,
            "POST",
            {
                order: order
            },
            {},
        );
        if (res && res?.xml && res?.session_id) {
            return res;
        } else {
            return false;
        }
    };

    getProductionConstants = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/settings/` + firm,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res[0] && res[0].production) {
            return res[0].production;
        } else {
            return false;
        }
    };
    _getSettingsFirmProduction = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/settings/` + firm,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res[0] && res[0]) {
            return res[0];
        } else {
            return false;
        }
    };

    getOrderDetail = async (id) => {

        if (!id) return false;

        const res = await this._send(
            `/orders/` + id,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.id) {
            return res;
        } else {
            return false;
        }

    };


    // getProduct = async (id) => {
    //     if (!id) {
    //         return false;
    //     }
    //     const res = await this._getData(
    //         `/products/${id}`,
    //         'GET',
    //         {},
    //         {},
    //         'json'
    //     );
    //     if (res && res.data && res.data.id) {
    //         return this._transformProduct(res.data);
    //     } else {
    //         return false;
    //     }
    // }

    // getProductsCategory = async (category, page = 1) => {
    //     const res = await this._getData(
    //         `/products`,
    //         'POST',
    //         {
    //             "category": category,
    //             "page": page,
    //         },
    //         {'Point-Action': 'category'},
    //         'json'
    //     );
    //     if (res && res.data) {
    //         return {
    //             data: this._transformProducts(res.data.data),
    //             count: res.data.count
    //         };
    //     } else {
    //         return false;
    //     }
    // }

    _downloadFile = (href) => {
        let link = document.createElement("a");

        link.setAttribute("href", href);
        link.setAttribute("target", "_blank");
        // link.setAttribute('rel', 'noopener noreferrer');
        link.setAttribute("download", "project.ifp");
        window.onload = link.click();
    };

    _getVarificationUser = async ({firm_id = "", payload}) => {
        return await this._send(
            `/registration/` + firm_id,
            "POST",
            {
                action: "qreg",
                // action: "check",
                ...payload
            },
            {},
            "json"
        );
    };
    _sendRegisterUser = async ({firm_id = "", payload}) => {
        return await this._send(
            `/registration/` + firm_id,
            "POST",
            {
                // action: "password_check",
                action: "qreg",
                ...payload
            },
            {},
            "json"
        );
    };
    _resetRegisterPassword = async (client_id, data) => {
        return await this._send(
            `/registration/` + client_id,
            "POST",
            {
                action: "forgot_password",
                login: data.login
            }
        );
    };
    _autorizedFirmRegister = async ({firm_id, payload}) => {
        return await this._send(
            `/registration/${firm_id}`,
            "POST",
            {
                action: "put_client_to_firm",
                ...payload
            },
            {},
            "json"
        );
    };

    _registerNewClient = async ({firm_id, payload}) => {
        return await this._send(
            `/registration/${firm_id}`,
            "POST",
            {
                // firm: firm_id,
                ...payload
            },
            {},
            "json"
        );
    };

    _sendBatchMachiningPart = async ({order, form}) => {
        const res = await this._send(
            `/templates/`,
            "PUT",
            {
                order: order,
                request: form

            },
            {},
            "json"
        );

        if (res) {
            return res;
        } else {
            return false;
        }
        // return res;
    };

    getStockMaterialClientData = async (payload) => {
        return await this._send("/clients", "POST", payload);
    };

    sendWordTranslation = async (words) => {
        return await this._send("/translate/", "POST", {need_translate: words});
    };
    changeLanguage = async (lang) => {
        return await this._send("/translate/", "PUT", {lang: lang});
    };
    sendPullAllDT = async (words) => {
        return await this._send("/translate/", "PUT", {put_all: 1, put_all_data: words});
    };

    getRegisterFirmName = async (key = "") => {
        return await this._send(`/ping/Heccrbqrjhf,kmblbyf[eq/${key}`, "GET");
    };
    _getFilesListManager = async (idProject) => {
        return await this._send(`/files/${idProject}/`, "GET");
        // return await  this._send(`/files/dtfazIts/`, "GET")
    };
    _deleteFilesListManager = async (url) => {
        return await fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: this._withCredentials,
            headers: {
                "Content-Type": "application/json",
                // 'Language': localStorage.getItem('ifurn_store_main_language') ? localStorage.getItem('ifurn_store_main_language') : 'ru',
                "User-Token": localStorage.getItem("ifurn_service_main_user_token"),
                "Client-Token": localStorage.getItem("ifurn_service_main_client_token"),
                "X-Api-Key": this._apiKey,
                "Language": localStorage.getItem("lang") || "ua"

            },
            redirect: "follow",
            referrerPolicy: "unsafe-url"
        })
            .then(data => {
                return data.json();
            })
            .catch(data => {
                return data.json();
            });
    };

    _onSetParamsSettings = async (payload) => {
        return await this._send(
            "/settings/",
            "POST",
            {...payload}
        );
    };
    _onSendInstructionTools = async (payload) => {
        return await this._send(
            "/tools/",
            "POST",
            payload
        );
    };
    _getGodeUser = async (id) => {
        return await this._send(`/clients/get_user/${id}`);
    };
    _removeToolsSettingFirm = async (id) => {
        return await this._send(
            "/tools/" + id,
            "DELETE"
        );
    };
    _updateCabinetStatusOrder = async ({orderId, statusId, userId}) => {
        return await this._send(`/orders/${orderId}`, "PUT", {status: statusId, user: userId});
    };
    _getCellStorageFullInfo = async (id) => {
        return await this._send("/goods_stock_cells/" + id, "GET");
    };
    _getCellsStorageCU = async () => {
        return await this._send("/goods_stock_cells/", "GET");
    };
    _setCellsStorageCU = async (payload) => {
        return await this._send("/goods_stock_cells/", "POST", payload);
    };
    _removeCellsStorage = async (id) => {
        return await this._send("/goods_stock_cells/" + id, "DELETE");
    };

    _getRemainsStorageFullInfo = async (id) => {
        return await this._send("/goods_stock/" + id, "GET");
    };
    _getRemains = async () => {
        return await this._send("/goods_stock/", "GET");
    };
    _setRemainsCU = async (payload) => {
        return await this._send("/goods_stock/", "POST", payload);
    };
    _removeRemains = async (id) => {
        return await this._send("/goods_stock/" + id, "DELETE");
    };

    _getPlans = async () => {
        return await this._send("/goods_stock_rest_plan/", "GET");
    };
    _getCuurentPlan = async (id) => {
        return await this._send("/goods_stock_rest_plan/" + id, "GET");
    };
    _updatePlan = async (payload) => {
        return await this._send("/goods_stock_rest_plan/", "POST", payload);
    };
    _removePlan = async (id) => {
        return await this._send("/goods_stock_rest_plan/" + id, "DELETE");
    };

    _getStockOrders = async () => {
        return await this._send("/goods_stock/orders/", "GET");
    };

    _servicePriceCU = async (dt) => {
        return await this._send("/goods_firm_user/", "POST", dt);
    };
    _materialProductPriceDelete = async (id) => {
        return await this._send("/goods_firm_user/" + id, "DELETE");
    };
    _deleteFurniture = async (url) => {
        const res = await fetch(url + "&api=1", {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: this._withCredentials,
            headers: {
                "Content-Type": "application/json",
                "User-Token": localStorage.getItem("ifurn_service_main_user_token"),
                "Client-Token": localStorage.getItem("ifurn_service_main_client_token"),
                "X-Api-Key": this._apiKey,
                "Language": localStorage.getItem("lang") || "ua"

            },
            redirect: "follow",
            referrerPolicy: "unsafe-url"
        });
        const body = await res.json();

        if (body.error_api) {
            this._errors = body.error_api;
            return false;
        }

        return body;
    };

    _deletePhotoFurniture = async (pic_article_id) => {
        return this._send("/boxes_files/" + pic_article_id, "DELETE");
    };
    _onSendPayRequestInvoice = (text) => {
        return this._send("/mail/", "POST", {
            action: "send_form_for_payment",
            text
        });
    };
    _sendLetter = ({text}) => {
        return this._send("/mail", "POST", {action: "send_form", text});
    };
    _getLinkPrintPdfCutting = ({order, print}) => {
        return this._send("/pdfreport", "POST", {order, print_needed: print});
    };

    _changeTurnDetail = (order) => {
        return this._send("/turn", "POST", {order});
    };
    _deleteOrderVersion = ({type = "v", code, v = ""}) => {
        return this._send(`/deleteorders/${type}/${code}/${v}`, "GET");
    };


    _fetchIfurnLables = async (orderId, part_id) => {
        const res = await fetch(`${this._baseUrl}/labels/${orderId}/${part_id}`, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer"
        });
        if (res.ok) {
            return await res.text();
        } else {
            return false;
        }
    };

    _fetchGiblabLabels = async (order) => {
        return await this._send("/labels/", "POST", {order: order});

    };

    _sendDocInMail = async (data) => {
        return await this._send("/mail", "POST", data);
    };

    _getCalculateValues = async (md) => {
        return await this._send(`/front_storage/${md}`, "GET");
    };
    _setCalculateValues = async (calculate) => {
        return await this._send("/front_storage", "POST", {data: calculate});
    };
    _materialExportBasis = async () => {
        return await this._send("/materialexportbasis", "GET");

    };
    _getPostformingTemplate = async (materialId) => {
        return await this._send("/pftemplate/" + materialId, "GET");
    };
    _createPostformingTempate = async ({materialId, data}) => {
        return await this._send("/pftemplate/" + materialId, "POST", data);

    };
    _authGetForUriClientsFirms = async (from) => {
        const fromData = new FormData();
        fromData.append("from", from)

        const res = await fetch(`${this._baseUrl}/authi/get-firms`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
            body: fromData
        });
        if (res.ok) {
            let response = await res.json();
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }
        return {error: `${res.statusText}`}

    };
    _authConnectedFirms = async (formData) => {
        const res = await fetch(`${this._baseUrl}/authi/append-firm`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
            body: formData
        });

        if (res.ok) {
            let response = await res.json();
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }
        return {error: `${res.statusText}`}

    };
    _authUpdateUserData = async (api_data) => {
        if (!Object.keys(api_data)) return Promise.reject('api_data empty');
        let id = api_data.client;
        let uri = `/clients/${id}`
        return await this._send(uri, "PUT", api_data);
    }

    _getMessageChannels = async () => {
        return this._send('/account/message-channels')
    }
    _setDefaultMessageChannel = async (form_data) => {
        return this._send_form_data({uri: '/account/set-default-message-channel', method: "POST", data: form_data})
    }
    _toggleEnabledMessageChannel = async (form_data) => {
        return this._send_form_data({uri: '/account/set-enabled-message-channel', method: "POST", data: form_data})
    }
    _confirmationMessageChannel = async (form_data) => {
        return this._send_form_data({uri: '/account/confirm-message-channel', method: "POST", data: form_data})
    }
    /** список подключенных соцсетей авторизованного пользователя */
    _getNetworkLogin = async () => {
        return this._send('/authi/network-login')

    }
    /**deleteNetworkLogin удалить привязку соцсети авторизованного пользователя
     * form-data:
     * network = fb/google
     * id = id из GET /api/authi/network-login.
     */
    _deleteNetworkLogin = async (api_data) => {
        let uri = `/authi/network-login?${new URLSearchParams(api_data)}`
        return this._send(uri, "DELETE")
    }
    /**
     * networkLogin авторизируем или регистрируем или подключаем сеть в ЛК пользователя.
     * data - зашифрованные данные в формате json - ниже распишу
     * network - fb/google
     * firms[] - массив фирм
     *
     * Данные в json следующие:
     * {
     *   "id" : "id пользователя в соцсети",
     *   "name": "Имя",
     *   "email" : "email",
     *   "phone":"phone",
     *   from: base64(uri)
     * }
     * @returns  {object}  - state.state = ok - все ок или ошибки
     */
    _networkLogin = async (form_data) => {
        return this._send_form_data({uri: '/authi/network-login', method: "POST", data: form_data})
    }
    _getNetworkLoginHash = async () => {
        return this._send('/authi/network-login-hash')
    }
    _authLookup = async (email_or_phone = null) => {
        return this._send_form_data({
            uri: '/authi/lookup', method: "POST", data: email_or_phone
        })
    }
    _authRegistration = async (form_data) => {
        return this._send_form_data({
            uri: '/authi/registration', method: "POST", data: form_data
        })
    }
    _logout = async () => {
        return this._send('/authn', 'DELETE')
    }
    _getNews = async (page = 1) => {
        return this._send(`/news?page=${page}`)
    }
    _getCuttingCard = async (order_id) => {
        return this._send(`/cuttingcard/${order_id}`)
    }
    _getGoodServicePartOrderFirm = async (currency = null) => {
        let url = `/goodsservicepartorderfirm`
        if (currency) url += `/${currency}`
        return this._send(url)
    }

}

export default ApiService;